<template>
  <div>
    <datalist id="address-results">
      <option
        v-for="result in results"
        :key="result.label"
        :value="result.label"
      >
        {{ result.label }}
      </option>
    </datalist>
    <l-map
      :zoom="zoom"
      :center="center"
      :options="{ zoomControl: false }"
      style="width: 100%; height: 200px; position: relative"
      @click="$emit('moveMarker')"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng" />
      <v-geosearch :options="geosearchOptions" />
    </l-map>
  </div>
</template>

<script>
import VGeosearch from 'vue2-leaflet-geosearch'
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: 'LeafletMaps',
  components: { VGeosearch, LMap, LMarker, LTileLayer },
  props: ['url', 'markerLatLng', 'geosearchOptions', 'zoom', 'center', 'results'],
}
</script>

<style scoped>

</style>
