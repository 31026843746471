<template>
  <div class="bg-black-0 rounded py-3 px-16 px-sm-32 mx-0">
    <h2>Tambah Mobil Unit</h2>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <ul>
          <li
            v-for="(input, key) in inputsLeft"
            :key="key"
            class="mb-3"
          >
            <label :for="key">{{ input.label }}</label>
            <b-form-input
              v-if="key !== 'address'"
              v-model="bloodvan[key]"
              :state="validation[key]"
              :name="key"
              :placeholder="input.placeholder"
              autocomplete="off"
            />
            <!--  NOTE: TURN ON THESE COMMENTED CODE IF YOU WANT TO USING LEAFLET MAPS-->
            <!--                    <b-form-input-->
            <!--                      v-else-->
            <!--                      v-model="bloodvan.address"-->
            <!--                      :state="validation.address"-->
            <!--                      name="address"-->
            <!--                      placeholder="Masukan Alamat Mobil Unit"-->
            <!--                      @input="searchLocation"-->
            <!--                      @change="handleAddressSelect"-->
            <!--                      list="address-results"-->
            <!--                    />-->
            <b-form-invalid-feedback :state="validation[key]">
              {{ fieldErrors[key] }}
            </b-form-invalid-feedback>
          </li>
          <!--  NOTE: TURN ON THESE COMMENTED CODE IF YOU WANT TO USING LEAFLET MAPS-->
          <!--                  <leaflet-maps-->
          <!--                    :results="results"-->
          <!--                    :zoom="zoom"-->
          <!--                    :center="center"-->
          <!--                    :marker-lat-lng="markerLatLng"-->
          <!--                    :geosearch-options="geosearchOptions"-->
          <!--                    :url="url"-->
          <!--                    @moveMarker="moveMarker"-->
          <!--                  />-->
        </ul>
        <ul>
          <li>
            <label>Lokasi MU</label>
            <google-maps
              :bloodvan="bloodvan"
              v-model="bloodvan.address"
              @address-extracted="handlePlaceSelected"
            />
          </li>
        </ul>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <ul>
          <li
            v-for="(input, key) in inputsRight"
            :key="key"
            class="mb-3"
          >
            <label :for="key">{{ input.label }}</label>
            <b-form-input
              v-model="bloodvan[key]"
              :state="validation[key]"
              :name="key"
              :placeholder="input.placeholder"
            />
            <b-form-invalid-feedback :state="validation[key]">
              {{ fieldErrors[key] }}
            </b-form-invalid-feedback>
          </li>
          <li>
            <div class="mb-3">
              <label>Jadwal Operasional</label>
              <b-form-select
                v-model="selectedOption"
                :options="optionsDayorDateChoice"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
              />
            </div>
            <b-form-group
              v-if="selectedOption === 'day'"
              :key="'day'"
            >
              <div
                v-for="option in options"
                :key="option.value"
                class="d-flex align-items-center position-relative"
              >
                <b-form-checkbox
                  v-model="option.checked"
                  :value="option.value"
                  name="days"
                  class="mr-2 mb-3"
                >
                  {{ option.name }}
                </b-form-checkbox>
                <div
                  class="d-flex position-absolute mb-3"
                  style="left: 150px"
                >
                  <b-button
                    size="sm"
                    variant="dark"
                    class="mr-2 btn-ghost"
                    disabled
                  >
                    08:00
                  </b-button>
                  <span>-</span>
                  <b-button
                    size="sm"
                    variant="dark"
                    class="ml-2 btn-ghost"
                    disabled
                  >
                    17:00
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <div v-else-if="selectedOption === 'date'">
              <div
                v-for="(input, index) in inputs"
                :key="index"
                class="d-flex mb-3 align-items-center"
              >
                <b-form-input
                  v-model="input.date"
                  type="date"
                  class="mr-3"
                />
                <b-button
                  size="sm"
                  disabled
                >
                  08:00
                </b-button>
                <strong class="mx-1"> - </strong>
                <b-button
                  size="sm"
                  disabled
                >
                  17:00
                </b-button>
                <i
                  style="font-size: 1.5em; cursor: pointer"
                  class="ri-close-circle-fill ml-3 text-danger"
                  @click="removeInputDate(index)"
                />
              </div>
              <div class="d-flex justify-content-end">
                <b-button
                  class="text-primary"
                  variant="link"
                  @click="addInputDate"
                >
                  Tambah
                </b-button>
              </div>
            </div>
          </li>
        </ul>
      </b-col>
      <b-col class="d-flex justify-content-end mb-3">
        <b-button
          variant="danger"
          class="mr-3"
          @click="$router.go(-1)"
        >
          Batal
        </b-button>
        <b-button
          v-b-modal.modal
          variant="primary"
        >
          Simpan
        </b-button>
      </b-col>

      <b-modal
        id="modal"
        hide-footer
        header-class="align-items-center"
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Konfirmasi
          </h5>
          <b-button
            variant="text"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
            @click="close"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            />
          </b-button>
        </template>
        <p>Apakah anda yakin ingin menyimpan data ini?</p>
        <div class="d-flex justify-content-end flex-wrap mt-32">
          <b-button
            variant="text"
            @click="$bvModal.hide('modal')"
          >Close</b-button>
          <b-button
            class="ml-16"
            variant="primary"
            :disabled="isLoading"
            @click="saveBloodvan"
          >Ok<b-spinner
            v-if="isLoading"
            small
          /></b-button>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import BloodvanService from '../../../../api/managebloodvan/manageBloodvanAPI'
import { latLng } from 'leaflet'
import 'leaflet/dist/leaflet.css'

import { OpenStreetMapProvider } from 'leaflet-geosearch'
import VGeosearch from 'vue2-leaflet-geosearch'

import LeafletMaps from '@/components/maps/LeafletMaps.vue'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'

export default {
  name: 'AddBloodvan',
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BModal,
    BFormInvalidFeedback,
    VGeosearch,
    LeafletMaps,
    GoogleMaps,
  },
  data() {
    return {
      isLoading: false,
      selectedOption: '',
      inputs: [{ date: '' }],
      modalType: '',
      geosearchOptions: {
        provider: new OpenStreetMapProvider(),
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 15,
      center: [-6.2088, 106.8456], // Jakarta coordinates
      markerLatLng: { lat: -6.2088, lng: 106.8456 },
      bloodvan: {
        id: null,
        name: '',
        service: '',
        address: '',
        location: '',
        lat: 0,
        long: 0,
        contact: '',
        description: '',
      },
      fieldErrors: {
        name: undefined,
        service: undefined,
        address: undefined,
        location: undefined,
        contact: undefined,
        description: undefined,
      },
      options: [
        { name: 'Senin', checked: false, value: 'monday' },
        { name: 'Selasa', checked: false, value: 'tuesday' },
        { name: 'Rabu', checked: false, value: 'wednesday' },
        { name: 'Kamis', checked: false, value: 'thursday' },
        { name: 'Jumat', checked: false, value: 'friday' },
        { name: 'Sabtu', checked: false, value: 'saturday' },
        { name: 'Minggu', checked: false, value: 'sunday' },
      ],
      optionsDayorDateChoice: [
        { value: '', text: 'Pilih Jenis Jadwal' },
        { value: 'date', text: 'Tanggal' },
        { value: 'day', text: 'Hari' },
      ],
      results: [],
    }
  },
  computed: {
    validation() {
      return Object.keys(this.fieldErrors).reduce((acc, key) => {
        acc[key] = this.fieldErrors[key] ? false : null
        return acc
      }, {})
    },
    inputsLeft() {
      return {
        name: { label: 'Nama Mobil Unit', placeholder: 'Nama Mobil Unit' },
        // address: {
        //   label: 'Alamat Mobil Unit',
        //   placeholder: 'Masukan Alamat Mobil Unit',
        // },
        location: {
          label: 'Venue',
          placeholder: 'Masukan Tempat MU',
        },
      }
    },
    inputsRight() {
      return {
        service: {
          label: 'Layanan',
          placeholder: 'Masukan Layanan Mobil Unit',
        },
        contact: {
          label: 'Kontak Mobil Unit',
          placeholder: 'Masukan Kontak Mobil Unit',
        },
        description: { label: 'Deskripsi', placeholder: 'Masukan Deskripsi' },
      }
    },
  },
  methods: {
    addInputDate() {
      this.inputs.push({
        date: '',
        open_hour: '08',
        open_minute: '00',
        close_hour: '17',
        close_minute: '00',
      })
    },
    removeInputDate(index) {
      this.inputs.splice(index, 1)
    },
    async saveBloodvan() {
      this.isLoading = true
      let schedule
      if (this.selectedOption === 'date') {
        schedule = this.inputs
          .filter(input => input.date)
          .map(input => ({
            date: input.date,
            open_hour: '08',
            open_minute: '00',
            close_hour: '17',
            close_minute: '00',
          }))
      } else if (this.selectedOption === 'day') {
        schedule = this.options
          .filter(option => option.checked)
          .map(option => ({
            day: option.name,
            open_hour: '08',
            open_minute: '00',
            close_hour: '17',
            close_minute: '00',
          }))
      } else {
        schedule = []
      }

      try {
        const response = await BloodvanService.addBloodvan({
          name: this.bloodvan.name,
          address: this.bloodvan.address,
          service: this.bloodvan.service,
          location: this.bloodvan.location,
          latitude: this.bloodvan.lat,
          longitude: this.bloodvan.long,
          contact: this.bloodvan.contact,
          description: this.bloodvan.description,
          pic: 'dummy pic data',
        })
        console.log('API Response:', response.data.data.id)
        const scheduleResponse = await BloodvanService.addSchedule({
          blood_van_id: response.data.data.id,
          schedule,
        })

        if (scheduleResponse) {
          this.$router.push({ name: 'bloodvans' })
        }
      } catch (error) {
        console.log(error)
      }

      this.$bvModal.hide('modal')
    },
    async searchLocation() {
      if (this.bloodvan.address.length > 3) {
        const { provider } = this.geosearchOptions
        this.results = await provider.search({ query: this.bloodvan.address })
      } else {
        this.results = []
      }
    },
    handleAddressSelect() {
      const selectedResult = this.results.find(
        result => result.label === this.bloodvan.address,
      )
      if (selectedResult) {
        this.markerLatLng = {
          lat: selectedResult.y,
          lng: selectedResult.x,
        }
        this.center = this.markerLatLng
        this.zoom = 15
      }
    },
    moveMarker(e) {
      this.markerLatLng = e.latlng
      // Reverse geocoding to get address
      const { provider } = this.geosearchOptions
      provider
        .search({ query: `${this.markerLatLng.lat}, ${this.markerLatLng.lng}` })
        .then(results => {
          if (results.length > 0) {
            this.bloodvan.address = results[0].label
          }
        })
    },
    handlePlaceSelected(place) {
      this.bloodvan.address = place.formattedAddress
      this.bloodvan.lat = place.latitude
      this.bloodvan.long = place.longitude
    },
  },
}
</script>
